import { Controller } from '@hotwired/stimulus';
import { Marked } from 'marked';

const MARKDOWN_CONFIG = {
  breaks: true,
  gfm: true,
  headerIds: false,
  mangle: false
};

// Connects to data-controller="skill-form"
export default class extends Controller {
  static targets = ['description', 'measuresOfProficiency'];

  initialize() {
    this.marked = new Marked(MARKDOWN_CONFIG);
  }

  applyComposerResponse(event) {
    const { prompt } = event.detail;
    if (prompt === 'prompts-composer-skillproficiencymetrics') {
      this.updateProficiencyMetrics(event.detail.content);
    } else if (prompt === 'prompts-composer-skilldescription') {
      this.updateDescription(event.detail.content);
    }
  }

  updateProficiencyMetrics(content) {
    const container = this.measuresOfProficiencyTarget;
    const { metrics } = JSON.parse(content);
    const addLink = container.querySelector('[data-cocooned-trigger="add"]');

    container.querySelectorAll('.nested-fields').forEach((field) => {
      const removeLink = field.querySelector('.cocooned-remove');
      if (removeLink) removeLink.click();
    });

    metrics.forEach(({ name, position }) => {
      addLink.click();
      const fields = container.querySelectorAll('.nested-fields');
      const newField = fields[fields.length - 1];
      newField.querySelector('textarea[name$="[name]"]').value = name;
      newField.querySelector('input[name$="[position]"]').value = position;
    });
  }

  updateDescription(content) {
    const { editor } = this.descriptionTarget;
    const trixContent = this.marked.parse(content);

    editor.recordUndoEntry('Composer Update');
    editor.setSelectedRange([0, editor.getDocument().getLength()]);
    editor.insertHTML(trixContent);
  }
}
