import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="composer"
export default class extends Controller {
  static targets = ['submitButton', 'pendingButton', 'composer', 'response', 'content'];

  static values = {
    prompt: String
  };

  connect() {
    this.configureMutationObserver();
  }

  disconnect() {
    this.observer?.disconnect();
  }

  configureMutationObserver() {
    this.observer = new MutationObserver(this.completed.bind(this));
    this.observer.observe(this.responseTarget, {
      characterData: true,
      childList: true,
      subtree: true
    });
  }

  submitClicked() {
    // It's form and composer have different requirements but this works for now.
    const form = this.element.closest('form');
    if (form && form.checkValidity()) {
      this.toggleButtons(true);
    }
  }

  toggleButtons(inProgress) {
    const allComposers = this.application.controllers.filter(
      ({ identifier }) => identifier === 'composer'
    );
    allComposers.forEach((composer) => {
      composer.submitButtonTarget.classList.toggle('d-none', inProgress);
      composer.pendingButtonTarget.classList.toggle('d-none', !inProgress);
    });
  }

  /**
   * This method is called by the mutation observer after response is generated
   * and dispatches a "completed" event which can be used to trigger actions on
   * external controllers.
   *
   * For example, to call `applyComposerResponse` on the
   * `audience_form_controller`, register the following action one the same
   * element the controller is attached to like so:
   *
   * <form
   *   data-controller="audience-form"
   *   data-action="composer:completed->audience-form#applyComposerResponse"/>
  */
  completed() {
    // TODO: Handle error responses here? Possibly just use the Turbo flash?
    if (this.hasContentTarget) {
      this.toggleButtons(false);
      this.dispatch(
        'completed',
        {
          detail: {
            content: this.contentTarget.innerHTML,
            prompt: this.promptValue
          }
        }
      );
    }
  }
}
