import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="audience-form"
export default class extends Controller {
  static targets = ['outcomes'];

  applyComposerResponse(event) {
    const { prompt } = event.detail;
    if (prompt === 'prompts-composer-audienceoutcomes') {
      this.updateOutcomes(event.detail.content);
    }
  }

  updateOutcomes(content) {
    const container = this.outcomesTarget;
    const { outcomes } = JSON.parse(content);
    const addLink = container.querySelector('[data-cocooned-trigger="add"]');
    container.querySelectorAll('.nested-fields').forEach((field) => {
      const removeLink = field.querySelector('.cocooned-remove');
      if (removeLink) removeLink.click();
    });

    outcomes.forEach(({ name }) => {
      addLink.click();
      const fields = container.querySelectorAll('.nested-fields');
      const newField = fields[fields.length - 1];
      newField.querySelector('textarea[name$="[name]"]').value = name;
    });
  }
}
