import { Controller } from '@hotwired/stimulus';
import Cocooned from '@notus.sh/cocooned';

import { isTestEnv } from '@/custom/rails_env';

// Connects to data-controller="cocooned"
export default class extends Controller {
  static removeRequiredAttributes(afterRemoveEvent) {
    const removedNode = afterRemoveEvent.currentTarget;
    removedNode.querySelectorAll('[required]').forEach((el) => el.removeAttribute('required'));
  }

  connect() {
    Cocooned.create(this.element, { animate: !isTestEnv() });
    this.element.addEventListener('cocoon:after-remove', this.constructor.removeRequiredAttributes);
  }
}
